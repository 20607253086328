/*======================================
=           28. 404 CSS Start          =
========================================*/
.error_page_start {
    padding: 150px 0px 100px 0px;
    text-align: center;
}
.hom_btn {
    .btn-secondary {
        background: $theme-color--default;
        border-radius: 35px;
        box-shadow: none;
        color: $white;
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
        height: 40px;
        line-height: 40px;
        margin-top: 40px;
        padding: 0 30px;
        text-shadow: none;
        text-transform: uppercase;
        transition: all 0.3s ease 0s;
        white-space: nowrap;
        &:hover{
            background: $body-text-color;
        }
    }
} 
.error_page_start{
    h2 {
        color: $body-text-color;
        font-size: 35px;
        margin-top: 0;
        text-transform: uppercase;
    }
    p {
        color: $body-text-color;
        font-size: 22px;
        font-weight: 300;
        line-height: 37px;
        margin: 20px auto 40px;
        text-align: center;
        width: 60%;
    }
}
/*======================================
=              404 CSS End           =
========================================*/