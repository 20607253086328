/*======================================
=        22. Checkout CSS Start        =
========================================*/
.user-actions{
    margin-bottom: 20px;
    & h5{
        font-size: 13px;
        font-weight: 400;
        background-color: $theme-background;
        padding: 15px 10px;
        border-top: 3px solid $mix-slid;
        margin-bottom: 0;
        & a{
            color: $mix-slid;
            font-size: 16px;
            font-weight: 700;
        }
    }
    .coupon-code{
        margin-top: 25px;
        padding: 20px 30px;
        .coupon-inner{
            padding: 0px;
        }
    }
}
.form_group {
    margin-bottom: 20px;
    & .form-label{
        font-size: 16px;
        display: block;
        line-height: 1;
        font-weight: 600;
        span{
            font-size: 18px;
            color: $theme-color--default;
        }
    }
    & .input-form {
        border: 1px solid $border;
        background: none;
        height: 40px;
        width: 100%;
        padding: 0 20px;
        @media #{$large-mobile}{
           width: 100%;
        }
    }
    & .login-register{
        display: inline-block;
        width: 80px;
        background: $theme-color--default;
        border: 0;
        color: $white;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 13px;
        margin-right: 20px;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        border-radius: 3px;
        &:hover{
            background: $theme-color--black;
        }
    }
}
.form_group input[type="checkbox"] {
	width: 15px;
	height: 15px;
	margin-right: 10px;
	position: relative;
	top: 3px;
}
.form_group.group_3 {
	display: flex;
	align-items: center;
    & .form-label{
        margin-bottom: 0;
        line-height: 34px;
        cursor: pointer;
        &:hover{
            color: $theme-color--black;
        }
    }
}
.checkout_info {
    border: 1px solid $border;
    margin-top: 25px;
    padding: 20px 30px;
    p{
        margin: 10px 0px;
        font-size: 15px;
    }
}
.form-head {
    background: $theme-color--black;
    color: $white;
    padding: 12px 10px;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 20px;
}
.niceselect-option{
    width: 100%;
    border-radius: 0px;
    ul{
        width: 100%;
        border-radius: 0px;
        li{
            font-size: 16px;
        }
    }
}
.custom-checkbox {
    width: fit-content;
    .form-check-input{
        height: 20px;
        width: 20px;
        margin-top: 0;
        opacity: 0;
        cursor: pointer;
        &:checked~.checkmark{
            background-color: $theme-color--default;
            &:after{
                left: 7px;
                opacity: 1;
            }
        }
    }
    .checkmark {
        top: 3px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $theme-background;
        z-index: -1;
        position: absolute;
        &:after{
            position: absolute;
            content: "";
            opacity: 0;
            left: 0;
            top: 3px;
            width: 6px;
            height: 11px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
    .form-check-label {
        padding-left: 10px;
        font-size: 16px;
        cursor: pointer;
        a{
            font-weight: 700;
        }
    }
}
.ship-box-info{
    margin-top: 20px;
}
.form-textarea{
    width: 100%;
}
.order_table{
    margin-bottom: 35px;
    & table{
         width: 100%;
        & thead{
            background: $same-white;
            & tr{
                & th{
                    min-width: 50%;
                    text-align: center;
                    padding: 15px 0;
                    border-bottom: 1px solid $borderbottom;
                }
                
            }
        }
        & tbody{
            & tr{
                & td{
                    min-width: 50%;
                    text-align: center;
                    padding: 15px 0;
                    border-right: 1px solid $borderbottom;
                    border-bottom: 1px solid $borderbottom;
                    font-weight: 500;
                    text-transform: capitalize;
                    font-size: 14px;
                    min-width: 150px;
                }
            }
        }
        & tfoot{
            & tr{
                & th{
                    min-width: 50%;
                    text-align: center;
                    padding: 15px 0;
                    border-bottom: 1px solid $borderbottom;
                }
                & td{
                    min-width: 50%;
                    text-align: center;
                    padding: 15px 0;
                    border-bottom: 1px solid $borderbottom;
                }
            }
        }
    }
}
.rs-padding {
    @media #{$large-mobile} {
        margin-top: 20px;
    }
}
.btn-secondary {
    background: $theme-color--default;
    border: none;
    outline: none;
    color: $white;
    padding: 10px 15px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
}
/*======================================
=           Checkout CSS Start         =
========================================*/