/* =============================================
=                CSS Index            =
============================================= */
/* 
    1. Default styles
    2. Header css area start
    3. Footer CSS Start
    4. Sidebar CSS Start
    5. Scroll Top Css Start
    6. Breadcrumb CSS Start
    7. Pagination Css Start
    8. Offcanvas Menu CSS Start
    9. Slider Area Start
    10. Shipping css area start
    11. Product css area start
    12. Sale Offer css area start
    13. Product Category css start
    14. Banner css area start
    15. Feature CSS Start
    16. Brand Logo CSS  Start
    17. Latest Post CSS Start
    18. Shop Css Area Start
    19. Blog CSS Start
    20. Product Details CSS Start
    21. Shopping Cart Page Css Start
    22. Checkout CSS Start
    23. login Register CSS Start
    24. Compare CSS Start
    25. My Account CSS Start
    26. Contact CSS Start
    27. Frequently Questions CSS Start
    28. 404 CSS Start
    29. Modal CSS Start

=====  End of CSS Index  ====== */

@import "0-base/variables";
@import "1-global/default";
@import "2-layout/header";
@import "2-layout/footer";
@import "2-layout/sidebar";
@import "3-elements/scroll-top";
@import "3-elements/breadcrumb";
@import "3-elements/pagination";
@import "4-components/offcanvas";
@import "4-components/hero-slider";
@import "4-components/shipping";
@import "4-components/product";
@import "4-components/sales-offer";
@import "4-components/product-tab";
@import "4-components/banner";
@import "4-components/feature";
@import "4-components/brand-logo";
@import "4-components/latest-post";
@import "4-components/shop";
@import "4-components/blog";
@import "4-components/product-details";
@import "4-components/shopping-cart";
@import "4-components/checkout";
@import "4-components/login-register";
@import "4-components/compare";
@import "4-components/my-account";
@import "4-components/contact";
@import "4-components/faq";
@import "4-components/404";
@import "4-components/modal";

