/* ====================================
=         4. Sidebar CSS Start        =
======================================= */
/*--=====---- Shop Sidebar  ---=====---*/
.sidebar-category-expand{
    .sub-menu{
        display: none;
    }
}
.shop-sidebar-category {
    padding: 30px;
    border: 2px solid $borderall;
}
.sidebar-title{
    .title-shop{
        font-size: 18px;
        line-height: 24px;
        border-bottom: 2px solid $borderbottom;
        padding: 0 0 10px;
        margin-bottom: 30px;
    }
}
.sidebar-category-expand{
    & > li{
        &.menu-item-has-children{
            &.menu-open{
              & > span{
                  &.menu-expand{
                      transform: rotate(180deg);
                  }
              }
            }
        }    
    }
}
.sidebar-category-expand{
    & > li{
        ul{
            li{
                &.menu-item-has-children{
                    &.menu-open{
                        span{
                            &.menu-expand{
                              transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
}
.sidebar-category-expand {
    li{
        position: relative;

        &:last-child{
            margin: 0px;

            a{
                border-bottom: 0px;
            }
        }

        span{
            &.menu-expand{
              position: absolute;
              right: 0px;
            }
        }
        a{
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            display: block;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid $border;

            &:hover{
                color: $theme-color--default;
            }
        }

        ul{
            &.sub-menu{
                padding-left: 20px;
            }
        }
    }
} 
.widget_inner{
    padding: 30px;
    &.widget-background{
        background: $theme-color--breadcrumb;
    }
}
.widget_list{
    margin-bottom: 29px;
    &:last-child{
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
    & h2{
        font-size: 16px;
        margin: 0 0 15px;
        padding: 0 0 5px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 24px;
    }
    & > ul{
        & > li{
            margin-bottom: 8px;
            position: relative;
            &:last-child{
                margin-bottom: 0;
            }
            & input{
                position: absolute;
                left: 3px;
                top: 50%;
                transform: translatey(-50%);
                opacity: 0;
                cursor: pointer;
                z-index: 999;
                &:checked ~ .checkmark{
                    background-color: $theme-color--default;
                    border: 1px solid $theme-color--default;
                    &::before{
                        display: block;
                    }
                }
            }
            & > a{
                font-size: 14px;
                display: block;
                line-height: 27px;
                margin-left: 30px;
                &:hover{
                    color: $theme-color--default;
                }
            }
            & span{
                &.checkmark{
                    height: 17px;
                    width: 17px;
                    border: 1px solid $border;
                    border-radius: 0px;
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translatey(-50%);
                    background: $same-white;
                    &::before{
                        left: 5px;
                        top: 3px;
                        width: 5px;
                        height: 8px;
                        border: solid $white;
                        border-top-width: medium;
                        border-right-width: medium;
                        border-bottom-width: medium;
                        border-left-width: medium;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                        content: "";
                        position: absolute;
                        display: none;
                       
                    }
                }
            }
            
        }
    }
}

/*--=====---- Price Range  ---=====---*/
.ui-slider-horizontal .ui-slider-range {
	background: $slid-btn;
	height: 5px;
}

.ui-slider-horizontal {
	height: 3px;
	background: #dbdbdb;
	border: none;
	width: 92%;
	margin: 0 auto;
    margin-bottom: 22px;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
	background: #ffff;
	border: 0;
	border-radius: 0;
	width: 19px;
    height: 19px;
    top: -7px;
	cursor: pointer;
    border-radius: 50%;
    border: 5px solid $theme-color--default;
}

.widget_list.widget_filter {
    & form{
        padding-top: 10px;
        & input {
            background: none;
            border: none;
            font-size: 12px;
            float: right;
            text-align: right;
            line-height: 31px;
            @media #{$desktop-device }{
                width: 65px;
            }
        }
        & button{
            height: 30px;
            line-height: 30px;
            padding: 0 20px;
            text-transform: capitalize;
            color: $white;
            background: $theme-color--default;
            border: 0;
            border-radius: 30px;
            &:hover{
                background: $action-link;
            }
        }
    }
}
/* ====================================
=          Sidebar CSS End            =
======================================= */