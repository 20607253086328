/* ===================================
 =     16. Brand Logo CSS  Start     =
==================================== */
.brand-logo{
    .single-brand{
        a{
            img{
                transition: all 300ms ease-in;
                transform: scale3d(1.01, 1.01, 1.01);
                display: inline-block;
            }
        }
        &:hover{
            img{
                transition: all 300ms ease-in;
                transform: scale3d(1.10, 1.10, 1.10);
            }
        }
    }
}
/* ===================================
 =        Brand Logo CSS  End      =
==================================== */

