/*======================================
=         26. Contact CSS Start        =
========================================*/
.contact-message{
    p{
        margin-bottom: 20px;
        font-size: 15px;
    }
    ul{
        li{
            border-top: 1px solid $border;
            padding: 10px 0;
            i{
                margin-right: 10px;
            }
        }
    }
}
.contact-map{
    border: 0px;
    width: 100%;
    height: 400px;
}
.contact-margin{
    margin-top: 40px;
    @media #{$default-tablet-width}{
        margin-top: 30px;
    } 
}
/*======================================
=             Contact CSS End          =
========================================*/